import moment from 'moment';
import 'moment/locale/ru';

const TIME_FORMAT = process.env.REACT_APP_TIMEFORMAT;

export const getDate = (data) => {
  const months = [
    `Января`,
    `Февраля`,
    `Марта`,
    `Апреля`,
    `Мая`,
    `Июня`,
    `Июля`,
    `Августа`,
    `Сентября`,
    `Октября`,
    `Ноября`,
    `Декабря`,
  ];
  const monthsInit = [
    `Январь`,
    `Февраль`,
    `Март`,
    `Апрель`,
    `Май`,
    `Июнь`,
    `Июль`,
    `Август`,
    `Сентябрь`,
    `Октябрь`,
    `Ноябрь`,
    `Декабрь`,
  ];
  const days = [
    `Воскресенье`,
    `Понедельник`,
    `Вторник`,
    `Среда`,
    `Четверг`,
    `Пятница`,
    `Суббота`,
  ];

  let time = {};
  let unixTime = new Date(data);

  time.year = unixTime.getFullYear();
  time.month = months[unixTime.getMonth()];
  time.initMonth = monthsInit[unixTime.getMonth()];
  time.numMonth = unixTime.getMonth();
  time.date = unixTime.getDate();
  time.day = days[unixTime.getDay()];
  time.hour = unixTime.getHours();
  time.minute = unixTime.getMinutes();

  return time;
};

export const times = {
  getTime(date = 1) {
    let obj = getDate(unixToMoment(date));
    const minute =
      obj.minute.toString().length === 1 ? '0' + obj.minute : obj.minute;
    let str = obj.hour + ':' + minute;
    return str;
  },

  getDay(date = 1) {
    let obj = getDate(unixToMoment(date));
    let str = obj.day;
    return str;
  },

  getDayOfWeek(date = 1) {
    let obj = getDate(unixToMoment(date));
    let str = `${obj.date} ${obj.month}`;
    return str;
  },

  getFullDate(date = 1) {
    let obj = getDate(unixToMoment(date));
    let str = `${obj.date} ${obj.month} ${obj.year}`;
    return str;
  },

  getNumberDate(date = 1) {
    let obj = getDate(unixToMoment(date));
    let day = obj.date < 10 ? `0${obj.date}` : obj.date;
    let month = obj.numMonth < 9 ? `0${obj.numMonth + 1}` : obj.numMonth + 1;
    let year = obj.year.toString().slice(2, 4);

    return `${day}.${month}.${year}`;
  },
  getNumberFullDate(date = 1) {
    let obj = getDate(unixToMoment(date));
    let day = obj.date < 10 ? `0${obj.date}` : obj.date;
    let month = obj.numMonth < 9 ? `0${obj.numMonth + 1}` : obj.numMonth + 1;
    let year = obj.year.toString();

    return `${day}.${month}.${year}`;
  },

  getYear(date = 1, m) {
    let obj = getDate(unixToMoment(date));
    let year = obj.year;

    return year;
  },
};

export const getCurrentPrice = (prices) => {
  const currentTime = Date.now() / 1000;

  return prices?.find((p) => {
    const startDate =
      typeof p.start_date === 'string'
        ? Date.parse(p.start_date) / 1000
        : p.start_date;

    return currentTime < startDate;
  });
};

export const checkRegDate = (date) => {
  return new Date() > new Date(unixToMoment(date));
};

export const checkOpenRegDate = (date) => {
  return new Date(unixToMoment(date)) > new Date();
};

export const getEventTime = (event, format = `D MMMM`) => {
  const time = moment.tz(unixToMoment(event?.start_time), event?.timezone);

  return `${time.format(format)}`;
};

export const getFormatTime = (time, timezone, format = 'DD.MM.YYYY') => {
  let timeTz = unixToMoment(time);

  if (timezone) {
    timeTz = unixToMoment(time).tz(timezone);
  }
  return `${timeTz.format(format)}`;
};

export const unixToMoment = (timestamp) => {
  if (TIME_FORMAT === 'timestamp') {
    return moment.unix(timestamp);
  } else if (TIME_FORMAT === 'iso') {
    return moment(timestamp);
  }

  return moment.unix(timestamp);
};

export const formatDateFromValidate = (dateString) => {
  const format = 'DD.MM.YYYY';
  const parsedDate = moment(dateString, format);

  if (!parsedDate.isValid()) {
    throw new Error('Invalid date format. Expected DD.MM.YYYY');
  }

  if (TIME_FORMAT === 'timestamp') {
    return parsedDate.unix();
  } else if (TIME_FORMAT === 'iso') {
    return parsedDate.toISOString();
  }

  throw new Error('Unsupported TIME_FORMAT. Use "timestamp" or "iso".');
};
