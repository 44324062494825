import NameSpace from '../name-space';

const NAME_SPACE = NameSpace.DATA;

export const getEvents = (state) => state[NAME_SPACE].events;
export const getFilteredEvents = (state) => state[NAME_SPACE].filteredEvents;
export const getFilteredEventsInsurance = (state) =>
  state[NAME_SPACE].filteredEventsInsurance;
export const getFilteredResults = (state) => state[NAME_SPACE].filteredResults;

export const getEventsBySelector = (state, selector, filter = `slider`) => {
  return state[NAME_SPACE].events.filter((el) => el[filter] === selector);
};

export const getEventById = (state, id) => {
  return state[NAME_SPACE].events.find((el) => el.public_id === id);
};
export const formatCity = (state) => state[NAME_SPACE].formatCity;
export const getIsJoinClub = (state) => state[NAME_SPACE].isJoinClub;
export const getIsLeaveClub = (state) => state[NAME_SPACE].isLeaveClub;
export const getCityId = (state) => state[NAME_SPACE].cityId;
export const getClubs = (state) => state[NAME_SPACE].clubs;
export const getClub = (state) => state[NAME_SPACE].club;
export const getUserClubs = (state) => state[NAME_SPACE].userClubs;
export const getCity = (state) => state[NAME_SPACE].city;
export const getFormats = (state) => state[NAME_SPACE].formats;
export const getFormatsById = (state) => state[NAME_SPACE].formatById;
export const getTeams = (state) => state[NAME_SPACE].teams;
export const getEventType = (state) => state[NAME_SPACE].eventType;
export const getEventCities = (state) => state[NAME_SPACE].eventCities;
export const getErrorOneNews = (state) => state[NAME_SPACE].errorOneNews;
export const getAllNews = (state) => state[NAME_SPACE].news;
export const getOneNews = (state) => state[NAME_SPACE].oneNews;
export const getNewsById = (state, id) => {
  return state[NAME_SPACE].news.find((el) => el.public_id === id);
};

export const getNewsWithoutId = (state, id) => {
  return state[NAME_SPACE].news.filter((el) => el.public_id !== id);
};
export const getResultCities = (state) => state[NAME_SPACE].resultCities;

export const getSizes = (state) => state[NAME_SPACE].sizes;
export const getSizesById = (state, id) =>
  state[NAME_SPACE].sizesById.find((el) => el.id === id);

export const getEventGoods = (state) => state[NAME_SPACE].eventGoods;
export const getEventGoodsById = (state, id) => {
  return state[NAME_SPACE]?.eventGoodsById?.find((el) => el?.id == id);
};
export const getEvent = (state) => state[NAME_SPACE].event;
export const getGood = (state) => state[NAME_SPACE].good;
export const getMedal = (state) => state[NAME_SPACE].medal;
export const getAddress = (state) => state[NAME_SPACE].address;

export const getDeliveryRegions = (state) => state[NAME_SPACE].delivery.regions;
export const getDeliveryCities = (state) => state[NAME_SPACE].delivery.cities;
export const getDeliveryPrice = (state) => state[NAME_SPACE].delivery.price;
export const getCompetitionTypes = (state) =>
  state[NAME_SPACE].competitionTypes;
export const getSubmitClubResultStatus = (state) =>
  state[NAME_SPACE].submitClubResultStatus;
export const getClubCompetitions = (state) =>
  state[NAME_SPACE].clubCompetitions;
export const getActiveUserInsurances = (state) =>
  state[NAME_SPACE].activeInsurances;
export const getPastUserInsurances = (state) =>
  state[NAME_SPACE].pastInsurances;
