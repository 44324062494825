import { capitalizeFirstLetter } from "utils/utils";
import styles from "./MyInsurance.module.scss";
import { getFormatTime } from "utils/date";

export const Insurance = ({ insurance }) => {

    return <li className={styles.insuranceWrapper}>
        <div className={styles.top}>
            <div className={styles.logoWrap}>
                {insurance.info.insurance.logo ? <img className={styles.logo} src={insurance.info.insurance.logo} /> : null}
            </div>
            <div>
                <p className={styles.itemLabel}>Номер:</p>
                <p className={styles.info}>{insurance.info.number || ""}</p>
            </div>
        </div>
        <p className={styles.itemLabel}>Тип страховки:</p>
        <p className={styles.info}>{insurance.info.insurance.name || ""}</p>
        <p className={styles.itemLabel}>Дата начала действия:</p>
        <p className={styles.info}>{insurance.info?.start_date ? getFormatTime(insurance.info.start_date, null, 'DD MMMM YYYY') : ""}</p>
        <p className={styles.itemLabel}>Действует дней:</p>
        <p className={styles.info}>{insurance.info?.insurance?.validity_time ? Math.floor(insurance.info.insurance.validity_time / 86400) : ""}</p>
        <p className={styles.itemLabel}>Застрахованный:</p>
        <p className={styles.info}>{capitalizeFirstLetter(insurance.info.last_name)} {capitalizeFirstLetter(insurance.info.first_name)} {insurance.info?.second_name ? capitalizeFirstLetter(insurance.info.second_name) : ""}</p>
        <p className={styles.itemLabel}>Контактный телефон:</p>
        <p className={styles.info}>{insurance.info.phone ? `+${insurance.info.phone}` : ""}</p>
        <a target={"_blank"} rel="noreferrer" href={insurance.info.insurance.legals} className={styles.btn}>Условия страхования</a>
    </li>;
};
