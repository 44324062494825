import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styles from "../index.module.scss";
import { useShallowEqualSelector } from "customHooks/useShallowEqualSelector";
import { Operation as DataOperation } from "reducer/data/data";
import {
    getUserClubs,
} from "reducer/data/selectors";
import { ClubsItem } from "../components/ClubsItem";
import ClubsAddPopup from "components/Popups/ClubsAddPopup/ClubsAddPopup";
import { Link } from "react-router-dom";
import { unixToMoment } from "utils/date";

export const MyClubs = ({ title, subtitle }) => {

    const dispatch = useDispatch();

    const [filteredClubs, setFilteredClubs] = useState([]);
    const [isPopupAddClub, setIsPopupAddClub] = useState(false);
    const [search, setSearch] = useState("");
    const [ascendingOrder, setAscendingOrder] = useState(true);
    const [ascendingOrderDate, setAscendingOrderDate] = useState(true);
    const clubs = useShallowEqualSelector((state) => getUserClubs(state));

    const handleSearchInputChange = (e) => {
        let filteredValues = [];

        setSearch(e.target.value);
        if (e.target.value && filteredClubs.length || e.target.value && filteredClubs.length) {
            filteredValues = filteredClubs?.filter((item) => {
                let itemName = JSON.stringify(item).toLowerCase();
                return itemName.includes(e.target.value.toLowerCase());
            });
        } else {
            filteredValues = clubs?.filter((item) => {
                let itemName = JSON.stringify(item).toLowerCase();
                return itemName.includes(e.target.value.toLowerCase());
            });
        }
        setFilteredClubs(filteredValues);
    };

    useEffect(() => {
        if (clubs?.length) {
            setFilteredClubs(sortDataByDate(clubs));
        }
    }, [clubs]);

    useEffect(() => {
        if (clubs?.length && !filteredClubs.length && !search) {
            setFilteredClubs(clubs);
        } else {
            if (clubs?.length > 0) {
                let filterClubs = filteredClubs;

                setSearch("");
                setFilteredClubs(filterClubs);
            }
        }

    }, []);


    useEffect(() => {
     /*    if (clubs?.length === 0) */ dispatch(DataOperation.loadUserClubs());
    }, [/* clubs?.length */]);


    const compareCharacters = (charA, charB) => {
        const russianPattern = /[а-яё]/i;
        const isRussianCharA = russianPattern.test(charA);
        const isRussianCharB = russianPattern.test(charB);

        if (isRussianCharA && !isRussianCharB) {
            return -1;
        } else if (!isRussianCharA && isRussianCharB) {
            return 1;
        } else {
            return charA.localeCompare(charB);
        }
    };

    const sortDataByTitle = (filteredClubs) => {
        const sortedData = [...filteredClubs].sort((a, b) => {
            const titleA = a.title.toLowerCase();
            const titleB = b.title.toLowerCase();

            let i = 0;
            let result = 0;

            while (i < titleA.length && i < titleB.length && result === 0) {
                result = compareCharacters(titleA.charAt(i), titleB.charAt(i));
                i++;
            }

            if (result === 0) {
                result = titleA.length - titleB.length;
            }

            return ascendingOrder ? -result : result;
        });

        setFilteredClubs(sortedData);
        setAscendingOrder(!ascendingOrder);

        return sortedData;
    };
    const sortDataByDate = (filteredClubs) => {
        const sortedData = [...filteredClubs].sort((a, b) => {
            return ascendingOrderDate ? unixToMoment(a.created_date) - unixToMoment(b.created_date) : unixToMoment(b.created_date) - unixToMoment(a.created_date);
        });
        setFilteredClubs(sortedData);
        setAscendingOrderDate(!ascendingOrderDate);

        return sortedData;
    };

    const clickSortName = () => {
        sortDataByTitle(filteredClubs);
    };
    const clickSortDate = () => {
        sortDataByDate(filteredClubs);
    };

    return (
        <>{clubs?.length > 0 ?
            <div>
                <h2 className={styles.title}>{title}</h2>
                <span className={styles.subtitle}>{subtitle}</span>

                <div className={styles.main}>

                    {isPopupAddClub && <ClubsAddPopup statePopupHandler={() => setIsPopupAddClub(false)} />}

                    <div className={styles.mainContent}>
                        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                            <label className={styles.label}>
                                <input
                                    onChange={handleSearchInputChange}
                                    className={styles.searchInput}
                                    type="text"
                                    value={search}
                                    placeholder="Введите название клуба"
                                />
                            </label>
                            <div className={styles.textWrapper}> <span onClick={clickSortDate} ><span style={{ marginRight: "30px" }} className={styles.text}>Сортировать:&nbsp;&nbsp;<span className={styles.icon}>По&nbsp;дате</span>
                                <svg style={{ marginLeft: "3px" }} width="10" height="14" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3 0L6 3H0L3 0Z" fill="#D9D9D9" style={{ opacity: ascendingOrderDate ? 0.5 : 1 }} />
                                    <path d="M3 7L6 4H0L3 7Z" fill="#D9D9D9" style={{ opacity: ascendingOrderDate ? 1 : 0.5 }} />
                                </svg>
                            </span>
                            </span><span onClick={clickSortName} ><span style={{ marginRight: "20px" }} className={styles.text}>Сортировать:&nbsp;&nbsp;<span className={styles.icon}>По&nbsp;названию</span>
                                <svg style={{ marginLeft: "3px" }} width="10" height="14" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3 0L6 3H0L3 0Z" fill="#D9D9D9" style={{ opacity: ascendingOrder ? 1 : 0.5 }} />
                                    <path d="M3 7L6 4H0L3 7Z" fill="#D9D9D9" style={{ opacity: ascendingOrder ? 0.5 : 1 }} />
                                </svg>
                            </span></span></div>
                            <ul className={styles.list}>  {filteredClubs?.length > 0 && filteredClubs.map((item, i) => <ClubsItem club={item} key={item.public_id} />)}</ul>
                        </div>
                    </div>
                </div>
            </div> :
            <div className={styles.noClubs}>
                <h3>Вы не состоите ни в одном клубе</h3>
                <div className={styles.wrapLinkButton}>
                    <Link style={{ textAlign: "center" }} className={styles.btnPart} to={'/clubs'}>Выбрать клуб</Link>
                </div>
            </div>
        }</>

    );
};


