import styles from '../OrderCard/OrderCard.module.scss';
import { getFormatTime } from "utils/date";
import { routes } from 'const/routes';
import { Link } from 'react-router-dom';
import { getOrderStatus, getOrderStatusIcon } from 'utils/orders';

const OrderCard = ({ item }) => {

  return (
    <li className={styles.item} >
      <div className={styles.itemWrapper}>
        <div className={styles.collumnLeft}>
          <p className={styles.info}>
            <span className={styles.itemLabel}>Заказ от:</span>
            {getFormatTime(item.created, null, 'DD.MM.YY')}
          </p>
          <div className={styles.test}>
            <img
              className={styles.icon}
              src={getOrderStatusIcon(item.status)}
              alt='Статус заказа'
            />
            {
              item.status ?
                <p className={styles.info}>
                  <span className={styles.itemLabel}>Статус заказа:</span>
                  {getOrderStatus(item.status)}
                </p>
                : null
            }
          </div>
          <div className={`${styles.test} ${styles.sumWrap}`}>
            <p className={styles.info}>
              <span className={styles.itemLabel}>Количество товаров:</span>
              {item.items.shop.length}
            </p>
            <p className={styles.info}>
              <span className={styles.itemLabel}>Сумма заказа:</span>
              {item.amount} Руб.
            </p>
          </div>
        </div>
        <Link className={styles.btn} to={`${routes.myOrders.path}/${item.public_id}`}>
          Подробнее о заказе
        </Link>
      </div>
      <div className={styles.cover}>
        <img
          className={styles.coverImg}
          // src={item.logo}
          src='https://herodev.ru/files/order_picture1.png'
          alt='Order Logo'
        />
      </div>
    </li>
  );
};

export default OrderCard;